import React, { useState, useEffect, useRef, useCallback } from "react"
import StepWizard from "react-step-wizard"
import { Redirect } from "@reach/router"
import styled from 'styled-components';

// GraphQL
import client from "../service/graphql"
import mutations from "../service/mutations"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import AboutVideo from "../steps/aboutVideo"
import SignUp from "../steps/signup"
import SuccessSignup from "../steps/successSignup"
import { formatNumber, extractNumber } from "../utils"
import HeaderProgress from "../components/HeaderProgress"

const Container = styled.div`
`;

const StepContainer = styled.div`
  padding-top: ${({ padding }) => padding ? '78px': '0'};
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const SignupPage = ({ location }) => {
  const [name, setName] = useState('Cadastro')
  const [description, setDescription] = useState('Sobre você')
  const [percent, setPercent] = useState(25)
  const [isVisible, setIsVisible] = useState(true)
  const [token, setToken] = useState(null)
  const [trackingUtm, setTrackingUtm] = useState({});
  const stepWizardRef = useRef();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const body = {
      origin: 'LP',
      utm_source: params.get('utm_source') || null,
      utm_medium: params.get('utm_medium') || null,
      utm_campaign: params.get('utm_campaign') || null,
      utm_term: params.get('utm_term') || null,
      utm_content: params.get('utm_content') || null,
    }

    setTrackingUtm(body)
  }, [])

  const submitAccount = async (data) => {
    try {
      const formattedNumber = formatNumber(data.phone)
      const {
        phone_country_code,
        phone_number,
      } = extractNumber(formattedNumber)

      const { createAccount } = await client.request(mutations.CREATE_ACCOUNT, {
        input: {
          phone_country_code,
          phone_number,
          ...trackingUtm
        },
      })

      client.setHeader("authorization", `Bearer ${createAccount.token}`)

      await client.request(mutations.CREATE_PERSON, {
        input: {
          name: data.name
        },
      })
      setIsVisible(true);
      return null
    } catch ({ response }) {
      return response.errors[0]
        ? response.errors[0].message
        : "ocorreu um erro inesperado"
    }
  }

  const handleStepChange = useCallback((e) => {
    const { activeStep } = e;

    switch (activeStep) {
      case 1:
        setIsVisible(true)
        break;
      case 2:
        setName('Cadastro')
        setDescription('Sobre você')
        setPercent(100)
        setIsVisible(false)
        break;
      case 2:
        setIsVisible(true)
        break;
    }
  }, [setIsVisible, setName, setDescription, setPercent])

  return (
    <Layout>
      <SEO title="Cadastro" />
      <Container>
        {!isVisible && <HeaderProgress name={name} description={description} percent={percent} toCancel='/signup' />}
        <StepContainer padding={!isVisible}>
          <StepWizard onStepChange={handleStepChange} ref={stepWizardRef}>
            <AboutVideo />
            <SignUp submitAccount={submitAccount} /> 
            <SuccessSignup />
          </StepWizard>
        </StepContainer>
      </Container>
    </Layout>
  )
}

export default SignupPage;
