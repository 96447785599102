import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  ContainerVideo,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../shared-styles"

const About = ({ nextStep }) => {

  function submit(e) {
    e.preventDefault()
    nextStep()
  }

  return (
    <form onSubmit={submit}>
      <Section>
        <Body>
          <Header>
            <Label><p>Entenda como a Veriza Funciona:</p></Label>
          </Header>
          <ContainerVideo>
            <iframe
              width="100%" 
              height="300" 
              src="https://www.youtube.com/embed/2cSEI0e9VZI"
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
          </ContainerVideo>
        </Body>
        <Buttons>
          <BackButton 
            onClick={() => navigate("/faq")} 
            style={{ textDecoration: "none" }}>
            Tenho dúvida
          </BackButton>
          <Button type="submit">
            Entendi
          </Button>
        </Buttons>
      </Section>
    </form>
  )
}

export default About
