import React, { useState } from "react"
import Photo from "../../images/consultor.png"
import AlertIcon from "../../images/fill-1.png"
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';

import {
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
} from "./styles"

import Alert from "../../components/alert"
import { Body, Buttons } from "../shared-styles"

const SignUp = ({ previousStep, nextStep, submitAccount }) => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null)

  async function onSubmit(e) {
    e.preventDefault()
    setError(null)
    const error = await submitAccount({ phone, name })

    if (error) {
      return setError(error)
    }
    nextStep()
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  function back() {
    previousStep()
  }

  return (
    <Section>
      <Body>
        <Header>
          <Profile>
            <User src={Photo} />
          </Profile>
          <Label><p>E aí? Se interessou pela Veriza? Faça seu pré cadastro e aproveite as melhores taxas!</p></Label>
        </Header>
        <Form>
          <Input
            placeholder="Digite o seu nome completo"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            error={formik.errors.name}
            helperText={formik.errors.name}
          />
          <Input
            placeholder="(DDD) 99999-9999"
            type="cel"
            mask="(99) 99999-9999"
            maskChar=""
            value={formik.values.phone}
            onChange={(e) => formik.setFieldValue('phone', e.target.value)}
            error={formik.errors.phone}
            helperText={formik.errors.phone}
          />
          <Input
            placeholder="Digite o seu email"
            onChange={({ target }) => setPhone(target.value)}
            mask="(99) 99999-9999"
            maskChar=""
            type="tel"
          />
        </Form>
        {error && <Alert icon={AlertIcon} description={error}></Alert>}
      </Body>
      <Buttons>
        <Button onClick={formik.submitForm} disabled={phone.length < 15 || name.length < 4} >
          Tenho interesse!
        </Button>
      </Buttons>
    </Section>
  )
}

export default SignUp
